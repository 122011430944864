<template>
    <Dialog id="dialog" v-model:visible="megjelenit" :style="{width: '450px', 'margin-top': '16px'}" 
        :header="beteg.new ? 'Beteg' : beteg.name" :modal="true" class="p-fluid"
        @show="dialogShow" @keydown.enter.ctrl="savePatient()" 
        @hide="hideDialog()" @keyup.esc="hideDialog()">
        <div style="margin-top: 4px">
            <span class="p-field p-float-label" style="width: 18%; display: inline-block">
                <InputText id="betegtitle" v-model.trim="v$.beteg.title.$model"
                    required="true" :class="{'p-invalid': v$.beteg.title.$invalid}" autocomplete="off"
                />
                <label for="fullname">Titulus</label>
            </span>
            <span class="p-field p-float-label" style="width: 82%; display: inline-block">
                <InputText id="betegname" ref="firstEditField" v-model.trim="v$.beteg.name.$model"
                    required="true" :class="{'p-invalid': v$.beteg.name.$invalid}" autocomplete="off"
                />
                <label for="fullname">Teljes név</label>
            </span>
        </div>
        <small class="p-error" v-if="v$.beteg.name.$error">{{ errorMessages.required }}</small>
        <div class="p-field">
            <SelectButton v-model="v$.beteg.sex.$model" required="true" :class="{'p-invalid': v$.beteg.sex.$invalid }" 
                :options="optionsSex" optionLabel="value" optionValue="code"
            />
            <small class="p-error" v-if="v$.beteg.sex.$error">{{ errorMessages.required }}</small>
        </div>
        <span class="p-field-checkbox" style="margin-bottom: 1.6rem">
            <Checkbox id="beteg_insocialhome" v-model="beteg.in_social_home" :binary="true"
            />
            <label for="beteg_insocialhome">Szoc. otthonos</label>
        </span>
        <span class="p-field p-float-label" v-if="beteg.in_social_home">
            <AutoComplete v-model="beteg.social_home_selected" :suggestions="filteredSocHomes" @complete="searchSocHome($event)" field="name"
                :minLength="2" autocomplete="off" placeholder="Keresés 2 karaktertől..."
                forceSelection dropdown>
            </AutoComplete>
            <label style="top: 1px; font-size: 12px;">Szociális otthon</label>
        </span>
        <span class="p-field p-float-label">
            <InputMask id="betegtaj" v-model.lazy="v$.beteg.taj.$model" mask="999-999-999"
                required="true" :class="{'p-invalid': v$.beteg.taj.$invalid}" autocomplete="off"
            />
            <label for="fullname">TAJ szám</label>
            <small class="p-error" v-if="v$.beteg.taj.$error">{{ errorMessages.required }}</small>
        </span>
        <span  class="p-float-label" v-if="!isTAJValid && errors!=null" v-bind:style="{ paddingBottom: '10px' }">
            <small class="p-error">{{ errors.get("TAJ") }}</small>
        </span>
        <!--span class="p-field p-float-label" style="width: 30%; display: inline-block">            
            <InputMask id="birthdate" v-model.lazy="beteg.birthdate" mask="9999.99.99"
                required="true" :class="{'p-invalid': v$.beteg.birthdate.$invalid}" autocomplete="off"
            />
            <label for="birthdate">Születési dátum</label> 
            <small class="p-error" v-if="v$.beteg.birthdate.validDate.$invalid">{{ errorMessages.wrongDate }}</small>            
            <small class="p-error" v-if="v$.beteg.birthdate.required.$invalid">Kötelező!</small>
        </span-->
        <!--span-- class="p-field p-float-label" style="width: 70%; display: inline-block">
            <InputText id="birthplace" v-model.trim.lazy="beteg.birthplace"
                autocomplete="off"
            />
            <label for="birthplace">Születési hely</label>    
            <small v-if="v$.beteg.birthdate.validDate.$invalid" style="color:white">.</small>  
            <small class="p-error" v-if="v$.beteg.birthdate.required.$invalid" style="opacity:0">.</small>             
        </!--span-->
        <span class="p-field p-float-label" style="width: 18%; display: inline-block">
            <InputMask id="postcode" ref="postcode" v-model="v$.beteg.postcode.$model" mask="9999"
                required="true" :class="{'p-invalid': v$.beteg.postcode.$invalid}" autocomplete="off" @focus="onFocusIRSZ"
            />
            <label for="postcode">IRSZ</label>
            <small class="p-error" v-if="v$.beteg.city.$error && !v$.beteg.postcode.$error" style="opacity:0">.</small>
            <small class="p-error" v-if="v$.beteg.postcode.$error">Kötelező!</small>
        </span>
        <span class="p-field p-float-label" style="width: 82%; display: inline-block">
            <InputText id="city" v-model.trim.lazy="v$.beteg.city.$model"
                required="true" :class="{'p-invalid': v$.beteg.city.$invalid}" autocomplete="beteg-city"
            />
            <label for="city">Város</label>
            <small class="p-error" v-if="!v$.beteg.city.$error && v$.beteg.postcode.$error" style="opacity:0">.</small>
            <small class="p-error" v-if="v$.beteg.city.$error">{{ errorMessages.required }}</small>
        </span>
        <div v-if="!isIRSZValid && errors!=null" style="padding-bottom: 10px">
            <small class="p-invalid">{{ errors.get("IRSZ") }}</small>
        </div>
        <span class="p-field p-float-label">
            <InputText id="address1" v-model.trim.lazy="v$.beteg.address1.$model"
                required="true" :class="{'p-invalid': v$.beteg.address1.$invalid}" autocomplete="off"
            />
            <label for="address1">Cím</label>
            <small class="p-error" v-if="v$.beteg.address1.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label" style="width: 50%; display: inline-block">
            <InputText id="tel1" v-model.trim.lazy="v$.beteg.tel1.$model" 
                required="true" :class="{'p-invalid': v$.beteg.tel1.$invalid}" autocomplete="off"
            />
            <label for="tel1">Telefonszám</label>
            <small class="p-error" v-if="v$.beteg.tel1.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label" style="width: 50%; display: inline-block">
            <InputText id="tel2" v-model.trim.lazy="beteg.tel2" 
                required="true" autocomplete="off"
            />
            <label for="tel2">Telefonszám 2</label>
            <small style="color: white" v-if="v$.beteg.tel1.$error">.</small>
        </span>
        <span class="p-field p-float-label">
            <AutoComplete v-if="!loading" v-model="beteg.address2_selected" :suggestions="filteredHospitals" @complete="searchHospital($event)" field="name"
                :minLength="2" autocomplete="beteg-address2" placeholder="Keresés 2 karaktertől névre, városra..."
                forceSelection :class="{'p-invalid': v$.beteg.address2_selected.$invalid }">
            </AutoComplete>
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label style="top: 1px; font-size: 12px;">Eszköz behelyezés helye</label>
            <small class="p-error" v-if="v$.beteg.address2_selected.$error">{{ errorMessages.required }}</small>
        </span>
        <span class="p-field p-float-label">
            <AutoComplete v-if="!loading" v-model="beteg.rephospital_selected" :suggestions="filteredHospitals" @complete="searchHospital($event)" field="name"
                :minLength="2" autocomplete="beteg-rephospital" placeholder="Keresés 2 karaktertől névre, városra..."
                forceSelection :class="{'p-invalid': v$.beteg.rephospital_selected.$invalid }">
            </AutoComplete>
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label style="top: 1px; font-size: 12px;">Lejelentő kórház</label>
            <small class="p-error" v-if="v$.beteg.rephospital_selected.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label" style="margin-top: 1rem">
            <AutoComplete v-if="!loading" v-model="v$.doctors.doctorE.selectedDoctor.$model" title="Keresés 2 karaktertől..." autocomplete="new-patient-doctor" 
                :suggestions="filteredDoctors" @complete="searchDoctor($event)" field="fullname" :minLength="2" forceSelection
                required="true" :class="{'p-invalid': v$.doctors.doctorE.$invalid }">
                <template #item="slotProps">
                    <div>{{slotProps.item.fullname}} - {{slotProps.item.prof2}}</div>
                </template>
            </AutoComplete>
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label style="top: 1px; font-size: 12px;">Eszközbehelyező orvos</label>
            <small class="p-error" v-if="v$.doctors.doctorE.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label" style="margin-top: 1rem">
            <AutoComplete v-if="!loading" v-model="v$.doctors.doctorK.selectedDoctor.$model" title="Keresés 2 karaktertől..." autocomplete="new-patient-doctor" 
                :suggestions="filteredDoctors" @complete="searchDoctor($event)" field="fullname" :minLength="2" forceSelection
                required="true" :class="{'p-invalid': v$.doctors.doctorK.$invalid }">
                <!--@item-select=""-->
                <template #item="slotProps">
                    <div>{{slotProps.item.fullname}} - {{slotProps.item.prof2}}</div>
                </template>
            </AutoComplete>
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label style="top: 1px; font-size: 12px;">Kezelőorvos</label>
            <small class="p-error" v-if="v$.doctors.doctorK.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label" style="margin-top: 1rem">
            <AutoComplete v-if="!loading" v-model="v$.doctors.doctorT.selectedDoctor.$model" title="Keresés 2 karaktertől..." autocomplete="new-patient-doctor" 
                :suggestions="filteredDoctors" @complete="searchDoctor($event)" field="fullname" :minLength="2" forceSelection
                required="true" :class="{'p-invalid': v$.doctors.doctorT.$invalid }">
                <!--@item-select=""-->
                <template #item="slotProps">
                    <div>{{slotProps.item.fullname}} - {{slotProps.item.prof2}}</div>
                </template>
            </AutoComplete>
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label style="top: 1px; font-size: 12px;">Lejelentő</label>
            <small class="p-error" v-if="v$.doctors.doctorT.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-if="!loading" v-model="v$.allapot.sonda.$model" :options="sondaOptions" optionLabel="name" optionValue="id" placeholder="" scrollHeight="360px"
                required="true" :class="{'p-invalid': v$.allapot.sonda.$invalid}"
            />
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label>Szonda behelyezés</label>
            <small class="p-error" v-if="v$.allapot.sonda.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-if="!loading" v-model="v$.allapot.sondatype.$model" :options="sondatypeOptions" optionLabel="name" optionValue="id" placeholder="" scrollHeight="300px"
                required="true" :class="{'p-invalid': v$.allapot.sondatype.$invalid}"
            />
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label>Szonda típus</label>
            <small class="p-error" v-if="v$.allapot.sondatype.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-if="!loading" v-model="v$.allapot.feedtype.$model" :options="feedtypeOptions" optionLabel="name" optionValue="id" placeholder="" scrollHeight="300px"
                required="true" :class="{'p-invalid': v$.allapot.feedtype.$invalid}"
            />
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label>Táplálás módja</label>
            <small class="p-error" v-if="v$.allapot.feedtype.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-if="!loading" v-model="v$.allapot.cond.$model" :options="condOptions" optionLabel="name" optionValue="id" placeholder=""
                required="true" :class="{'p-invalid': v$.allapot.cond.$invalid}"
            />
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label>Állapot</label>
            <small class="p-error" v-if="v$.allapot.cond.$error">{{ errorMessages.required }}</small>
        </span>

        <span class="p-field p-float-label">
            <Dropdown v-if="!loading" v-model="v$.allapot.activity.$model" :options="activityOptions" optionLabel="name" optionValue="id" placeholder=""
                required="true" :class="{'p-invalid': v$.allapot.activity.$invalid}"
            />
            <Skeleton v-if="loading" height="2.6rem" class="mb-2"></Skeleton>
            <label>Aktivitás</label>
            <small class="p-error" v-if="v$.allapot.activity.$error">{{ errorMessages.required }}</small>
        </span>


        <span class="p-field p-float-label">
            <Textarea id="description" v-model="beteg.note" rows="3" cols="20" style="resize: vertical"
            />
            <label for="description">Megjegyzés</label>
        </span>

        <template #footer>
            <small class="p-error p-d-block" v-if="v$.beteg.$error || v$.doctors.$error || v$.allapot.$error">{{ errorMessages.formError }}</small>
            <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                v-tooltip.bottom="'ESC'" tabindex="-1"
                @click="hideDialog"/>
            <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                @click="savePatient"/>
        </template>
    </Dialog>
</template>

<script>
import PatientService from '@/service/backend/patient.service'
import HospitalService from '@/service/backend/hospital.service'
import DoctorService from '@/service/backend/doctor.service'
import useVuelidate from '@vuelidate/core'
import { required, helpers, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'
import { store } from '@/store';

const validDate = (value, vm) => !helpers.req(value) || value.includes('_') || vm.$dayjs(value,'YYYY.MM.DD', true).isValid()

export default {
    props: ['show','dialogData'],
    
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },

    data() {

        const megjelenit = reactive({})
        const beteg = reactive({})
        
        return {
            User: store.getters['user/User'],
            
            errors: null,
            validIRSZ: true,
            validTAJ: true,
            megjelenit,
            errorMessages,
            submitted: false,
            loading: false,

            beteg,
            doctors: {
                doctorE: {}, doctorK: {}, doctorT: {}
            },
            allapot: {},

            filteredSocHomes: null,
            filteredHospitals: null,
            filteredDoctors: null,

            optionsSex: [{'code': 'M', 'value' :'Férfi'}, {'code': 'F', 'value' :'Nő'}],

            sondaOptions: [{id:'1',name:'nasogastricus szonda'},{id:'2',name:'nasojejunalis szonda'},{id:'3',name:'endoscopos gastrostoma'},{id:'4',name:'sebészi gastrostoma'}
                          ,{id:'5',name:'sebészi jejunostoma'},{id:'6',name:'endoscopos jejunostoma'},{id:'E',name:'egyéb'}],
            sondatypeOptions: [{id:'1',name:'nasogastricus szonda'},{id:'2',name:'nasojejunalis szonda'},{id:'3',name:'PEG'},{id:'4',name:'PEJ'}
                          ,{id:'5',name:'gastrotubus'},{id:'6',name:'button'},{id:'7',name:'katéter'},{id:'8',name:'jejunokath'},{id:'E',name:'egyéb'}],
            feedtypeOptions: [{id:'B',name:'bólus'},{id:'G',name:'gravitációs szerelék'},{id:'P',name:'pumpa'},{id:'E',name:'szájon át étkezik'}],
            condOptions: [{id:'A',name:'teljes ápolásra szorul'},{id:'S',name:'segítségre szorul'},{id:'O',name:'önellátó'}],
            activityOptions: [{id:'J',name:'fennjáró beteg',energy:25},{id:'F',name:'fekvő beteg',energy:20}],

        }
    },
    validations: {
        beteg: {
            title: {},
            name: { required },
            sex: { required },
            taj: { required },
            city: { required },
            postcode: { required },
            address1: { required },
            tel1: {  },
            birthdate: { validDate },
            address2_selected: { required },
            rephospital_selected: { required },
        },
        doctors: {
            doctorE: { selectedDoctor: { required } },
            doctorK: { selectedDoctor: { required } },
            doctorT: { selectedDoctor: { required } },
        },
        allapot: {
            sonda: { required },
            sondatype: { required },
            feedtype: { required },
            cond: { required },
            activity: { required },
        }
    },
	patientService: null,
	hospitalService: null,
    doctorService: null,

	created() {
        this.patientService = new PatientService();
        this.hospitalService = new HospitalService();
        this.doctorService = new DoctorService()
        this.megjelenit = false;
	},
    methods: {
        dialogShow() {
            this.beteg.saved = false
            this.$refs.firstEditField.$el.focus()
        },
        hideDialog() {
            this.megjelenit = false
            this.$emit('update:show', false)
        },
        searchSocHome(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.patientService.getSocHomesForSelect({filters: event.query })
                .then( result => {
                    this.filteredSocHomes = result
                })
            }, 600)
        },
        searchHospital(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.hospitalService.getHospitalsForSelect({filters: event.query })
                .then( result => {
                    this.filteredHospitals = result
                })
            }, 600)
        },
        searchDoctor(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.doctorService.getDoctorsForSelect({filters: event.query })
                .then( result => {
                    this.filteredDoctors = result
                })
            }, 600)
        },
        doctorNeedsToUpdate(type){
            return (  this.doctors['doctor'+type].doctorid == undefined && this.doctors['doctor'+type].selectedDoctor != null 
                    || 
                      this.doctors['doctor'+type].doctorid != undefined && this.doctors['doctor'+type].selectedDoctor == null 
                    || 
                      this.doctors['doctor'+type].doctorid != undefined && this.doctors['doctor'+type].selectedDoctor != null 
                      && this.doctors['doctor'+type].doctorid != this.doctors['doctor'+type].selectedDoctor.id)
        },
        async savePatient() {            
            this.v$.beteg.$touch()
            this.v$.doctors.$touch()
            this.v$.allapot.$touch()
            if (!this.v$.beteg.$invalid && !this.v$.doctors.$invalid && !this.v$.allapot.$invalid) {

                this.submitted = true
                
                let oObj = this.optionsSex.find(o => o.code == this.beteg.sex)
                this.beteg.sex_val = oObj.value
                this.beteg.social_home =  (this.beteg.social_home_selected== undefined) ? '' : this.beteg.social_home_selected.id
                this.beteg.social_home_name =  (this.beteg.social_home_selected== undefined) ? '' : this.beteg.social_home_selected.name
                this.beteg.rephospital =  (this.beteg.rephospital_selected== undefined) ? '' : this.beteg.rephospital_selected.id
                this.beteg.rephospital_name =  (this.beteg.rephospital_selected== undefined) ? '' : this.beteg.rephospital_selected.name
                this.beteg.address2 =  (this.beteg.address2_selected== undefined) ? '' : this.beteg.address2_selected.id
                this.beteg.address2_name =  (this.beteg.address2_selected== undefined) ? '' : this.beteg.address2_selected.name

                await this.save()

                if(this.beteg.saved){

                    /* Először működjön a beteg mentés... aztán majd a dokikat is mentjük.*/
                    let doctorUpdate = { customerid: this.beteg.id, hiba: false, doctors: [] } // csak a változást küldjük el

                    if( this.doctorNeedsToUpdate('E') )
                        doctorUpdate.doctors.push({ evtype: 'E', doctorid: (this.doctors.doctorE.selectedDoctor==null) ? null : this.doctors.doctorE.selectedDoctor.id })
                    if( this.doctorNeedsToUpdate('K') )
                        doctorUpdate.doctors.push({ evtype: 'K', doctorid: (this.doctors.doctorK.selectedDoctor==null) ? null : this.doctors.doctorK.selectedDoctor.id })
                    if( this.doctorNeedsToUpdate('T') )
                        doctorUpdate.doctors.push({ evtype: 'T', doctorid: (this.doctors.doctorT.selectedDoctor==null) ? null : this.doctors.doctorT.selectedDoctor.id })

                    if(doctorUpdate.doctors.length > 0){
                        //const updateddoctors = 

                        try{
                            await this.patientService.updateDoctor(doctorUpdate)

                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Orvosok módosítva!', life: 3000})

                            //this.orvosok.doctor = updateddoctors.doctors
                            //this.$emit('update:dialogData', this.orvosok)
                        }catch{
                            this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Orvosok mentésénél hiba történt!', life: 3000})
                            doctorUpdate.hiba = true;
                        }
                    }

                    if(!doctorUpdate.hiba){

                        if(typeof(this.allapot.date) == "object"){
                            this.allapot.date = this.$dayjs(this.allapot.date.toString()).format('YYYY.MM.DD')
                            this.allapot.evdate1 = this.allapot.date
                        }else{
                            this.allapot.evdate1 = this.$dayjs().format('YYYY.MM.DD')
                        }
                        
                        this.allapot.customerid = this.beteg.id
                        this.allapot.evtype = '2' // Felvett, ezt használjuk a manager-i rögzítés azonosítására

                        try{
                            this.allapot.id = await this.patientService.updateStatus(this.allapot)
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Státusz mentve!', life: 3000})
                            this.allapot.saved = true
                        }catch{
                            this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Státusz mentésénél hiba történt!', life: 3000})
                            this.allapot.hiba = true;
                        }

                        if(!this.allapot.hiba){

                            this.$emit('update:dialogData', this.beteg)
                            this.hideDialog()

                        }
                    
                    }
                    
                }

                this.submitted = false

            }
        },
        async save() {

            this.beteg.saved = false

            try{

                let title = (this.beteg.title == undefined) ? '' : this.beteg.title+' '
                this.beteg.title_name = title + this.beteg.name

                if(this.notEmpty(this.uploadedFileId)){
                    this.beteg.patientfile_id = this.uploadedFileId
                    this.beteg.patientfile_name = this.uploadedFileName
                }

                if (this.beteg.id && this.beteg.id < 200000000) {
                    this.beteg.id = await this.patientService.updatePatient(this.beteg)
                    //this.patients[this.findIndexById(this.beteg.id)] = this.patient TODO visszaadni
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                }
                else {
                    const newPatient = await this.patientService.newPatient(this.beteg)
                    this.beteg.id = newPatient.id
                    this.beteg.homecarer = newPatient.homecarer
                    this.beteg.status = '2'
                    /*if(this.patients.length == this.$refs.dt.rows){
                        this.patients.pop()
                    }
                    this.patients.unshift(this.patient) TODO majd visszaadni és ott kell pusholni */
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                }

                this.beteg.saved = true
                this.beteg.updated = this.$dayjs().format('YYYY.MM.DD HH:mm:ss')
                
            }catch(e){
                //console.log(e)
                if(e.response.status === 422){
                    
                    let keys = Object.keys(e.response.data)
                    let values = Object.values(e.response.data)

                    this.errors = new Map();
                    for (let i = 0; i < keys.length; i++){
                        this.errors.set(keys[i], values[i]);
                    }
                    
                    if(this.errors.has("IRSZ")){
                        this.validIRSZ = false;
                    }
                    if(this.errors.has("TAJ")){
                        this.validTAJ = false;
                    }

                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: this.errors.entries().next().value[1], life: 3000})                   
                    
                }
                else{
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Valami hiba történt!", life: 3000})
                } 
            }

        },
        checkErrors: function(){
            if(this.v$.beteg.postcode.$error){ 
                this.validIRSZ = true
            }
            if(this.v$.beteg.taj.$error){ 
                this.validTAJ = true
            }
        },
        onFocusIRSZ: function() {
            if(this.postcode === '' || this.postcode === undefined || this.postcode === null )
                this.simulateKeyPressHome()
        },
        simulateKeyPressHome: function() {
            setTimeout(() => {
                this.$refs.postcode.$el.setSelectionRange(0, 0);
            }, 100);
        },
        notEmpty(val) {
            // TODO ez is lehetne valami mixin, sok helyen kéne használni
            return val!==null && val!=='' && val!==undefined
        },
        async loadDoctors() {

            let doc = {}

            const doctors = await this.patientService.getPatientDoctors(this.beteg.id)
            
            let doctorE = { selectedDoctor: null }
            if(this.notEmpty(doc = doctors.find(d => d.type == 'E'))){
                doctorE.doctorid = doc.doctorid
                doctorE.selectedDoctor = { id: doc.doctorid, fullname: doc.name }
            }

            let doctorK = { selectedDoctor: null }
            if(this.notEmpty(doc = doctors.find(d => d.type == 'K'))){
                doctorK.doctorid = doc.doctorid
                doctorK.selectedDoctor = { id: doc.doctorid, fullname: doc.name }
            }

            let doctorT = { selectedDoctor: null }
            if(this.notEmpty(doc = doctors.find(d => d.type == 'T'))){
                doctorT.doctorid = doc.doctorid
                doctorT.selectedDoctor = { id: doc.doctorid, fullname: doc.name }
            }

            return { doctorE, doctorK, doctorT }

        },
        async loadStatus() {

            let status = await this.patientService.getPatientStatus(this.beteg.id)

            return status

        }
    },
    computed: {
        /*isAddress2String: function() {
            return this.beteg.address2 !== null && this.beteg.address2 !== "" && isNaN(Number(this.beteg.address2))
        },*/
        isIRSZValid: function(){
            this.checkErrors()
            return this.validIRSZ
        },
        isTAJValid: function(){
            this.checkErrors()
            return this.validTAJ
        }
    },
    watch: {
        show: function(v) {
            this.megjelenit = v
        },
        dialogData: async function(v) {
            if(this.show){
                this.beteg = v
                this.validIRSZ = true;
                this.validTAJ = true;

                if(this.notEmpty(this.beteg.id)){
                    this.submitted = true
                    this.loading = true

//                    if(this.beteg.status==="2"){
                        
  //                  }

                    let hospitalName = ""
                    
                    hospitalName = await this.hospitalService.getHospitalName(this.beteg.address2)
                    this.beteg.address2_selected = {'code': this.beteg.address2 ?? "", 'name': hospitalName ?? ""}

                    hospitalName = await this.hospitalService.getHospitalName(this.beteg.rephospital)
                    this.beteg.rephospital_selected = {'code': this.beteg.rephospital ?? "", 'name': hospitalName ?? ""}

                    this.doctors = await this.loadDoctors()
                    this.allapot = await this.loadStatus()

                    this.loading = false
                    this.submitted = false
                }else{
                    this.doctors = { doctorE: {}, doctorK: {}, doctorT: {} },
                    this.allapot = {}
                }

                // TESZT
                /*
                if(this.beteg.id === undefined){

                    this.beteg.name = 'Aaa Alamár'
                    this.beteg.sex = 'M'
                    this.beteg.taj = '301-465-778'
                    this.beteg.city = 'Kiskunfélegyháza'
                    this.beteg.postcode = '6100'
                    this.beteg.address1 = 'Almateszt utca 4.'
                }
                */
            }
        }
    }
}
</script>

<style>

</style>