<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>

                <!-- Táblázat -->

				<DataTable ref="dt" :value="patients" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            sortField="lastvisit_date" :sortOrder="-1" :loading="loading" @filter="onFilter()" @row-click="expandRow"
                            :lazy="true" :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)"
                            stateStorage="local" stateKey="dt-state-patients-local" @state-restore="onStateRestore($event)"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
                            <div>
                                <Button :icon="(loading) ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'" class="p-button-raised p-mr-4" @click="setDefaultAndLoad()"
                                        v-tooltip.right="'Alaphelyzet és frissítés'"/>
                                <Button label="Új" v-if="!isManager" icon="pi pi-plus" class="p-button-success p-mr-2 l-mobile" @click="newPatientDialog=true" />
                                <Button label="Új beteg" v-if="!isManager" icon="pi pi-plus" class="p-button-success p-mr-2 l-nonmobile" @click="openNew" />
                                <Button label="Új szoc.otthon" v-if="!isManager" icon="pi pi-plus" class="p-button-success p-mr-2 l-nonmobile" @click="openNewSocHome" />
                                <Button label="Felvétel" v-if="isAdmin||isManager" icon="pi pi-plus" class="p-button-success p-mr-2 l-nonmobile" @click="openNewRecord" />
                                <h5 class="p-m-0 p-d-inline-block" v-html="isManager ? 'Betegek' : 'Betegek, otthonok'"></h5>
                            </div>
                            <div class="l-break"></div> <!-- break -->
                            <div class="l-filter">
                                <div class="p-d-inline" style="margin-right: 1.5rem" v-if="!isManager">
                                    <InputSwitch id="showOnlyFelvettekSwitch" v-model="showOnlyFelvettek" @change="onFilter()"/>
                                    <label for="showOnlyFelvettekSwitch">Felvettek</label>
                                    <Badge :value="felvettekSzama" severity="warning" class="p-overlay-badge p-ml-1" style="font-size: 1rem"></Badge>
                                </div>                                    
                                <div class="p-d-inline l-nonmobile" style="margin-right: 1.5rem" v-if="!isManager">
                                    <InputSwitch id="showOnlySocHomesSwitch" v-model="showOnlySocHomes" @change="onFilter()"/>
                                    <label for="showOnlySocHomesSwitch">Otthonok</label>
                                </div>                                    
                                <div class="p-d-inline" style="margin-right: 1.5rem" v-if="!isManager">
                                    <InputSwitch id="showClosedSwitch" v-model="showClosed" @change="onFilter()"/>
                                    <label for="showClosedSwitch">Lezártak is</label>
                                </div>                                    
                                <span class="p-input-icon-left">
                                    <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                    <InputText v-model="searchGlobal" placeholder="Keresés..." />
                                </span>
                            </div>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="title_name" header="Teljes név" style="min-width:16rem" sortable></Column>
					<!--Column field="sex_val" header="Nem" headerStyle="width: 4rem"></!--Column-->
					<!--Column field="taj" header="TAJ szám" headerStyle="width: 10rem" sortable></Column-->
                    <Column field="social_home_name" header="Szoc. otthon" sortable></Column>
                    <Column v-for="col of extraColumns" :field="col.field" :header="col.header" :key="col.field" sortable></Column>
					<Column v-if="showOnlyFelvettek" field="createdat" header="Felvétel" headerStyle="width: 8rem" sortable></Column>
					<Column v-if="!showOnlyFelvettek" field="lastvisit_date" header="Utolsó vizit" headerStyle="width: 8rem" sortable></Column>
					<Column v-if="!showOnlyFelvettek" field="lastorder_date" header="Utolsó rendelés" headerStyle="width: 8rem" sortable></Column>
					<Column v-if="!showOnlyFelvettek" field="protvisit_state" header="Prot.k. vizit" headerStyle="width: 6rem">
                        <template #body="slotProps">
                            <Badge v-if="slotProps.data.protvisit_state == 'K'" value="Kérelmezett" class="visit-status-K"/>
                            <Badge v-if="slotProps.data.protvisit_state == 'J'" value="Jóváhagyott" class="visit-status-J"/>
                            <Badge v-if="slotProps.data.protvisit_state == 'X'" value="Elutasított" class="visit-status-X"/>                            
                        </template>
                    </Column>
                    <Column field="status" header="Státusz" headerStyle="width: 6.5rem" sortable>
						<template #body="slotProps">
                            {{statusNames[slotProps.data.status]}}
                        </template>
                    </Column>
                    <Column v-if="!showOnlyFelvettek" field="patientfile_id" headerStyle="width: 6rem" header="Beleegyező nyilatkozat" sortable>
                        <template #body="slotProps">
                            <Badge value="Feltöltve" class="fmp" v-if="slotProps.data.patientfile_id"></Badge>
                        </template>
                    </Column>
					<Column headerStyle="width: 20%">
						<template #body="slotProps">
							<Button icon="pi pi-list" label="Részletek" class="p-button-rounded p-button-success p-mr-2" style="height:42px" @click="detailsPatient(slotProps.data.id)" v-if="!isManager && !isHianyos(slotProps.data)" />
							<Button icon="pi pi-pencil" :label="(isManager || isHianyos(slotProps.data)) ? 'Szerkeszt' : ''" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" v-tooltip.top="'Töröl'" class="p-button-rounded p-button-warning" @click="confirmDeletePatient(slotProps.data)" v-if="isAdmin" />
							<Button icon="pi pi-times" v-tooltip.top="'Visszavon'" class="p-button-rounded p-button-danger" @click="unRecord(slotProps.data)" v-if="isManager || isHianyos(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col p-grid">
                                    <div class="p-col-2" style="min-width: fit-content;">
                                       TAJ szám:
                                    </div>
                                    <div class="p-col-10">
                                        <span class="l-clickselect" v-html="slotProps.data.taj"></span>
                                    </div>
                                    <div class="p-col-2" style="min-width: fit-content;">
                                       Címe:
                                    </div>
                                    <div class="p-col-10">
                                        <span class="l-clickselect" v-html="fullAddress(slotProps.data)"></span>
                                    </div>
                                    <div class="p-col-2" style="min-width: fit-content;">
                                       Telefon:
                                    </div>
                                    <div class="p-col-10">
                                        <span class="l-clickselect">{{ slotProps.data.tel1 }}</span>
                                        <span v-if="slotProps.data.tel2">, <span class="l-clickselect">{{ slotProps.data.tel2 }}</span></span>
                                        <span v-if="slotProps.data.rel1tel1">, <span class="l-clickselect">{{ slotProps.data.rel1tel1 }}</span></span>
                                    </div>
                                    <div class="p-col-4" v-if="slotProps.data.patientfile_id">
                                        <span>'Beleegyező nyilatkozat':</span>
                                    </div>
                                    <div class="p-col-12" v-if="slotProps.data.patientfile_id">
                                        <SplitButton icon="pi pi-search" :label="slotProps.data.patientfile_name"
                                            @click="viewPatientFile(slotProps.data.patientfile_id)" :model="fileSecondaryButton(slotProps.data.patientfile_id)"
                                        />
                                    </div>
                                </div>
                                <div class="p-col p-grid">
                                    <div class="p-col-2" style="min-width: 92px;">
                                        Megjegyzés:
                                    </div>
                                    <div class="p-col-10">
                                        <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                    </div>
                                    <div class="p-col-2" style="min-width: 92px;">
                                        Rögzítve:
                                    </div>
                                    <div class="p-col-10">
                                        <p class="p-d-inline" style="white-space: pre-wrap">
                                            {{ slotProps.data.createdby }}<br/>
                                            {{ slotProps.data.createdat }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--div v-if="slotProps.data.status == 2 && slotProps.data.statusDetails == undefined && !expandedDetailsLoading">
                                <span class="p-field" style="display: inline-block">
                                    <Button label="Adatok betöltése" class="p-ml-2" @click="loadExpandStatusDetails" />
                                </span>
                            </div-->
                            <Divider v-if="slotProps.data.statusDetails != undefined || expandedDetailsLoading"/>
                            <div v-if="slotProps.data.statusDetails != undefined || expandedDetailsLoading" class="p-grid">
                                <div class="p-col p-grid">
                                    <div class="p-col-4">
                                        Eszköz behelyezés helye:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading"
                                            v-html="slotProps.data.address2_name"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                    <div class="p-col-4">
                                        Lejelentő kórház:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading"
                                            v-html="slotProps.data.rephospital_name"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                    <div class="p-col-4">
                                        Eszközbehelyező orvos:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading"
                                            v-html="slotProps.data.doctorDetails.doctorE"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                    <div class="p-col-4">
                                        Kezelőorvos:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading"
                                            v-html="slotProps.data.doctorDetails.doctorK"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                    <div class="p-col-4">
                                        Lejelentő:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading"
                                            v-html="slotProps.data.doctorDetails.doctorT"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                </div>
                                <!-- <<<<< új oszlop >>>>> -->
                                <div class="p-col p-grid">
                                    <div class="p-col-4">
                                        Szonda behelyezés:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading" class="p-d-inline"
                                            v-html="slotProps.data.statusDetails.sondaName"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                    <div class="p-col-4">
                                        Szonda típus:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading" class="p-d-inline"
                                            v-html="slotProps.data.statusDetails.sondatypeName"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                    <div class="p-col-4">
                                        Táplálás módja:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading" class="p-d-inline"
                                            v-html="slotProps.data.statusDetails.feedtypeName"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                    <div class="p-col-4">
                                        Állapot:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading" class="p-d-inline"
                                            v-html="slotProps.data.statusDetails.allapotName"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                    <div class="p-col-4">
                                        Aktivitás:
                                    </div>
                                    <div class="p-col-8">
                                        <p v-if="!expandedDetailsLoading" class="p-d-inline"
                                            v-html="slotProps.data.statusDetails.activityName"></p>
                                        <Skeleton v-else height="1.5rem"></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </template>                            
                        </Card>
                    </template>
                </DataTable>


                <!-- Beteg Szerkesztő ablak -->

                <Dialog id="dialog" v-model:visible="patientDialog" :style="{width: '450px'}" 
                    :header="patient.new ? 'Beteg' : patient.name" :modal="true" class="p-fluid"
                    @show="dialogShow" @keydown.enter.ctrl="savePatient()" @keyup.esc="hideDialog()">
                    <div style="margin-top: 4px">
                        <span class="p-field p-float-label" style="width: 18%; display: inline-block">
                            <InputText id="patienttitle" v-model.trim.lazy="v$.patient.title.$model" 
                                required="true" :class="{'p-invalid': v$.patient.title.$invalid}" autocomplete="off"
                            />
                            <label for="fullname">Titulus</label>
                            <small class="p-invalid" v-if="v$.patient.name.$error" style="opacity:0">.</small>
                        </span>
                        <span class="p-field p-float-label" style="width: 82%; display: inline-block">
                            <InputText id="patientname" ref="firstEditField" v-model.trim.lazy="v$.patient.name.$model" 
                                required="true" :class="{'p-invalid': v$.patient.name.$invalid}" autocomplete="off"
                            />
                            <label for="fullname">Teljes név</label>
                            <small class="p-error" v-if="v$.patient.name.$error">{{ errorMessages.required }}</small>
                        </span>
                    </div>
                    <div class="p-field">
                        <SelectButton v-model="v$.patient.sex.$model" 
                            required="true" :class="{'p-invalid': v$.patient.sex.$invalid }" 
                            :options="optionsSex" optionLabel="value" optionValue="code"
                        />
                        <small class="p-error" v-if="v$.patient.sex.$error">{{ errorMessages.required }}</small>
                    </div>
                    <span class="p-field-checkbox">
                        <Checkbox id="patient_insocialhome" v-model="patient.in_social_home" :binary="true"
                        />
                        <label for="patient_insocialhome">Szoc. otthonos</label>
                    </span>
                    <span class="p-field p-float-label" style="margin-top: 1rem" v-if="patient.in_social_home">
                        <AutoComplete v-model="patient.social_home_selected" :suggestions="filteredSocHomes" @complete="searchSocHome($event)" field="name"
                            :minLength="2" autocomplete="off" placeholder="Keresés 2 karaktertől..."
                            forceSelection dropdown>
                        </AutoComplete>
                        <label style="top: 1px; font-size: 12px;">Szociális otthon</label>
                    </span>
                    <span class="p-field p-float-label">
                        <InputMask id="patienttaj" ref="patienttaj" v-model.lazy="v$.patient.taj.$model" mask="999-999-999" @click="clickInputMask('patienttaj')"
                            required="true" :class="{'p-invalid': v$.patient.taj.$invalid}" autocomplete="off"
                        />
                        <label for="fullname">TAJ szám</label>
                        <small class="p-error" v-if="v$.patient.taj.$error">{{ errorMessages.required }}</small>
                    </span>
                    <span  class="p-float-label" v-if="!isTAJValid && errors!=null" v-bind:style="{ paddingBottom: '10px' }">
                        <small class="p-error">{{ errors.get("TAJ") }}</small>
                    </span>
                    <span class="p-field p-float-label" style="width: 30%; display: inline-block">
                        <InputMask id="birthdate" ref="birthdate" v-model.lazy="v$.patient.birthdate.$model" mask="9999.99.99" @click="clickInputMask('birthdate')"
                            :class="{'p-invalid': v$.patient.birthdate.$invalid}" autocomplete="off"
                        />
                        <small class="p-error" v-if="v$.patient.birthdate.validDate.$invalid">{{ errorMessages.wrongDate }}</small>
                        <small class="p-error" v-if="v$.patient.birthdate.$error && !v$.patient.birthdate.validDate.$invalid">Kötelező!</small>
                        <label for="birthdate">Születési dátum</label>
                    </span>
                    <span class="p-field p-float-label" style="width: 70%; display: inline-block">
                        <InputText id="birthplace" v-model.trim.lazy="patient.birthplace"
                            autocomplete="off"
                        />
                        <label for="birthplace">Születési hely</label>                        
                        <small v-if="v$.patient.birthdate.validDate.$invalid || v$.patient.birthdate.$error" style="color:white">.</small>            
                    </span>
                    
                    <span class="p-field p-float-label" style="width: 18%; display: inline-block">
                        <InputMask id="postcode" ref="postcode" v-model.lazy="v$.patient.postcode.$model" mask="9999" @click="clickInputMask('postcode')"
                            required="true" :class="{'p-invalid': v$.patient.postcode.$invalid}" autocomplete="new-hosp-postcode"
                        />
                        <label for="postcode">IRSZ</label>                      
                        <small class="p-error" v-if="v$.patient.city.$error && !v$.patient.postcode.$error" style="opacity:0">.</small>
                        <small class="p-error" v-if="v$.patient.postcode.$error">Kötelező!</small>
                    </span>
                    <span class="p-field p-float-label" style="width: 82%; display: inline-block">
                        <InputText id="city" v-model.trim.lazy="v$.patient.city.$model" 
                            required="true" :class="{'p-invalid': v$.patient.city.$invalid}" autocomplete="new-hosp-city"
                        />
                        <label for="city">Város</label>
                        <small class="p-error" v-if="!v$.patient.city.$error && v$.patient.postcode.$error" style="opacity:0">.</small>
                        <small class="p-error" v-if="v$.patient.city.$error">{{ errorMessages.required }}</small>
                    </span>
                    <div v-if="!isIRSZValid && errors!=null" style="padding-bottom: 10px">
                        <small class="p-error">{{ errors.get("IRSZ") }}</small>
                    </div>
                    <span class="p-field p-float-label">
                        <InputText id="address1" v-model.trim.lazy="v$.patient.address1.$model" 
                            required="true" :class="{'p-invalid': v$.patient.address1.$invalid}" autocomplete="off"
                        />
                        <label for="address1">Cím</label>
                        <small class="p-error" v-if="v$.patient.address1.$error">{{ errorMessages.required }}</small>
                    </span>
                    <span class="p-field p-float-label" style="width: 50%; display: inline-block">
                        <InputText id="tel1" v-model.trim.lazy="v$.patient.tel1.$model" 
                            required="true" :class="{'p-invalid': v$.patient.tel1.$invalid}" autocomplete="off"
                        />
                        <label for="tel1">Telefonszám</label>
                        <small class="p-error" v-if="v$.patient.tel1.$error">{{ errorMessages.required }}</small>
                    </span>
                    <span class="p-field p-float-label" style="width: 50%; display: inline-block">
                        <InputText id="tel2" v-model.trim.lazy="patient.tel2" 
                            required="true" autocomplete="off"
                        />
                        <label for="tel2">Telefonszám 2</label>
                        <small style="color: white" v-if="v$.patient.tel1.$error">.</small>
                    </span>
                    <span class="p-field p-float-label" style="width: 50%; display: inline-block">
                        <InputMask id="firstvisitdate" v-model.lazy="v$.patient.firstvisitdate.$model" mask="9999.99.99"
                            :class="{'p-invalid': v$.patient.firstvisitdate.$invalid}" autocomplete="off"
                        />
                        <small class="p-error" v-if="v$.patient.firstvisitdate.validDate.$invalid">{{ errorMessages.wrongDate }}</small>
                        <small class="p-error" v-if="v$.patient.firstvisitdate.required.$invalid && v$.patient.firstvisitdate.$error">{{ errorMessages.required }}</small>
                        <label for="firstvisitdate">Első beteglátogatás dátuma</label>
                    </span>
                    <span class="p-field" style="width: 10%; display: inline-block">
                        <Button label="Ma" class="p-ml-2" @click="setFirstVisitToday" />
                    </span>
                    <span class="p-field" style="display:block">
                        <label class="l-fileupload-label">'Beleegyező nyilatkozat'</label>
                        <FileUpload name="files[]" mode="basic" class="p-d-inline p-mr-2" style="margin-left: 1rem" :chooseLabel="'Választ'" 
                            :fileLimit="20971520" :key="fileUploadKey" accept=".xls,.xlsx,.png,.jpg,.pdf" :customUpload="true" @uploader="myFileUploader" :auto="true"
                            :disabled="isUploading" :class="{'p-invalid': v$.uploadedFileName.$invalid}"
                        />
                        <span v-html="uploadedFileName ?? patient.patientfile_name" v-show="!isUploading"></span>
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem" v-show="isUploading"></i>
                        <small class="p-error" v-if="v$.uploadedFileName.$error">{{ errorMessages.required }}</small>
                    </span>
                    <div class="p-field">
                        <label class="l-fileupload-label">A beteg beleegyezését adta:</label>
                        <SelectButton v-model="v$.patient.fmp_consent.$model" 
                            :class="{'p-invalid': v$.patient.fmp_consent.$invalid }" 
                            :options="optionsFmpConsent" optionLabel="value" optionValue="code"
                        />
                        <small class="p-error" v-if="v$.patient.fmp_consent.$error">{{ errorMessages.required }}</small>
                    </div>
                    <span class="p-field p-float-label">
                        <Textarea id="description" v-model="patient.note" rows="3" cols="20" style="resize: vertical"
                        />
                        <label for="description">Megjegyzés</label>
                    </span>

                    <template #footer>
                        <small class="p-error p-d-block" v-if="v$.patient.$error || v$.uploadedFileName.$error">{{ errorMessages.formError }}</small>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.top="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
                        <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.top="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="savePatient"/>
                        <Button label="Mentés és tovább" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.top="'Részletek megnyitása és státusz rögzítése'" :disabled="v$.$error || submitted"
                            @click="savePatientAndForward"  v-if="patient.new || patient.status==='2'" />
                    </template>
                </Dialog>

                <!-- Szoc.otthon Szerkesztő ablak -->

				<Dialog id="socHomeDialog" v-model:visible="socHomeDialog" :style="{width: '450px', 'margin-top': '16px'}" 
                    header="Szociális otthon" :modal="true" class="p-fluid"
                    @show="dialogShow2" @keydown.enter.ctrl="saveSocHome()" @keyup.esc="hideDialog()">
                    <span class="p-field p-float-label">
                        <InputText id="sochomename" ref="firstEditField2" v-model.trim.lazy="v$.sochome.name.$model" 
                            required="true" :class="{'p-invalid': v$.sochome.name.$invalid}" autocomplete="off"
                        />
                        <label for="fullname">Megnevezés</label>
                        <small class="p-error" v-if="v$.sochome.name.$error">{{ errorMessages.required }}</small>
                    </span>
                    <span class="p-field p-float-label">
                        <InputText id="city" v-model.trim.lazy="v$.sochome.city.$model"
                            required="true" :class="{'p-invalid': v$.sochome.city.$invalid}" autocomplete="new-hosp-city"
                        />
                        <label for="city">Város</label>
                        <small class="p-error" v-if="v$.sochome.city.$error">{{ errorMessages.required }}</small>
                    </span>
                    <span class="p-field p-float-label">
                        <InputMask id="postcode" v-model.lazy="v$.sochome.postcode.$model" mask="9999"
                            required="true" :class="{'p-invalid': v$.sochome.postcode.$invalid}" autocomplete="new-hosp-postcode"
                        />
                        <label for="postcode">IRSZ</label>
                        <small class="p-error" v-if="v$.sochome.postcode.$error">{{ errorMessages.required }}</small>
                    </span>
                    <span class="p-field p-float-label">
                        <InputText id="address1" v-model.trim.lazy="v$.sochome.address1.$model"
                            required="true" :class="{'p-invalid': v$.sochome.address1.$invalid}" autocomplete="off"
                        />
                        <label for="address1">Cím</label>
                        <small class="p-error" v-if="v$.sochome.address1.$error">{{ errorMessages.required }}</small>
                    </span>
                    <span class="p-field p-float-label" style="width: 50%; display: inline-block">
                        <InputText id="tel1" v-model.trim.lazy="v$.sochome.tel1.$model"
                            required="true" :class="{'p-invalid': v$.sochome.tel1.$invalid}" autocomplete="off"
                        />
                        <label for="tel1">Telefonszám</label>
                        <small class="p-error" v-if="v$.sochome.tel1.$error">{{ errorMessages.required }}</small>
                    </span>
                    <span class="p-field p-float-label" style="width: 50%; display: inline-block">
                        <InputText id="tel2" v-model.trim.lazy="sochome.tel2" 
                            required="true" autocomplete="off"
                        />
                        <label for="tel2">Telefonszám 2</label>
                        <small style="color: white" v-if="v$.sochome.tel1.$error">.</small>
                    </span>
                    <span class="p-field p-float-label">
                        <Textarea id="description" v-model="sochome.note" rows="3" cols="20" style="resize: vertical"
                        />
                        <label for="description">Megjegyzés</label>
                    </span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.sochome.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveSocHome"/>
					</template>
				</Dialog>

                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deletePatientDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="patient">Biztosan töröljük a beteget (otthont): <b>{{patient.name}}</b> ?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deletePatientDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deletePatient" :disabled="submitted" />
					</template>
				</Dialog>

                <!-- Visszavonás felugró -->

				<Dialog v-model:visible="unRecordDialog" :style="{width: '450px'}" header="Visszavonás" :modal="true" class="p-fluid">
					<div class="confirmation-content" style="margin-bottom: 2rem;">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="patient">Visszavonja a beteget: <b>{{patient.name}}</b> ?</span>
					</div>
                    <span class="p-field p-float-label">
                        <Dropdown v-model="v$.visszavonas.reason.$model" :options="reasonOptions" optionLabel="name" optionValue="id" placeholder="" scrollHeight="300px"
                            required="true" :class="{'p-invalid': v$.visszavonas.reason.$invalid}"
                        />
                        <label>Visszavonás oka</label>
                        <small class="p-invalid" v-if="v$.visszavonas.reason.$error">{{ errorMessages.required }}</small>
                    </span>

                    <span class="p-field p-float-label">
                        <Textarea v-model="visszavonas.note" rows="4" cols="20" style="resize: vertical"
                        />
                        <label>Megjegyzés</label>
                    </span>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="unRecordDialog = false"/>
						<Button label="Visszavonás" :icon="(submitted) ? 'pi pi-spin pi-spinner' : ''" class="p-button-danger" 
                            @click="unRecordPatient" :disabled="submitted" />
					</template>
				</Dialog>

                <!-- Új mobilon kérdés felugró -->

				<Dialog v-model:visible="newPatientDialog" :style="{width: '450px'}" header="Új hozzáadása" :modal="true">
					<div class="confirmation-content">
                        <Button label="Új beteg" v-if="!isManager" icon="pi pi-plus" class="p-button-success p-mr-2" @click="newPatientDialog=false; openNew();" />
                        <Button label="Új otthon" v-if="!isManager" icon="pi pi-plus" class="p-button-success p-mr-2" @click="newPatientDialog=false; openNewSocHome();" />
                        <Button label="Felvétel" v-if="isAdmin||isManager" icon="pi pi-plus" class="p-button-success p-mr-2" @click="newPatientDialog=false; openNewRecord();" />
					</div>
				</Dialog>

                <PatientRecordDialog v-model:show="isOpenPatientRecordDialog" v-model:dialogData="dialogData"/>

			</div>
		</div>

        <FileViewer v-model:show="isOpenFileViewer" v-model:fileName="viewFileName" v-model:fileData="viewFileData"/>

	</div>

</template>

<script>
//import Vue from 'vue'
//import { mapGetters, mapActions } from 'vuex'
import PatientService from '@/service/backend/patient.service'
import HospitalService from '@/service/backend/hospital.service'
import PatientRecordDialog from '@/components/patient/PatientRecordDialog'

import FileViewer from '@/components/patient/FileViewer'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, helpers, errorMessages } from  '@/texts/errorMessages'   // TODO vuelidate-ben az adott hibához van $message -> azt kellene kiírni errorMessages helyett! Persze előbb magyarítani. 
import { reactive } from 'vue'
import { store } from '@/store';

const validDate = (value, vm) => !helpers.req(value) || value.includes('_') || vm.$dayjs(value,'YYYY.MM.DD', true).isValid()

export default {
    components: {
        FileViewer, PatientRecordDialog
    },
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {

        const patient = reactive({})
        const filters = reactive({})
        const viewFileData = reactive({})
        const viewFileName = reactive({})

		return {

            User: store.getters['user/User'],
            
            init: true,
            errors: null,
			patients: [],
			patientDialog: false,
			socHomeDialog: false,
			deletePatientDialog: false,
			unRecordDialog: false,
            newPatientDialog: false,


            isOpenPatientRecordDialog: false,
            dialogData: {},

            patient,
            sochome: { type: 'S', sex: 'S' },
            areas: null,
            filteredSocHomes: null,
            validIRSZ: true,
            validTAJ: true,
			expandedRows: [],
            expandedDetailsLoading: false,
			filters,
            submitted: false,
            searchGlobal: "",
            totalRecords: 0,
            lazyParams: {},
            showClosed: false,
            showOnlySocHomes: false,
            showOnlyFelvettek: false,
            loading: false,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,
            felvettekSzama: 0,

            extraColumns: [],
            optionsSex: [{'code': 'M', 'value' :'Férfi'}, {'code': 'F', 'value' :'Nő'}],
            statusNames: {'0': 'Rögzítve', '1': 'Aktív', '2': 'Felvett', '5': 'Aktív', '9': 'Lezárva'},
            optionsFmpConsent: [{'code': 1, 'value' :'Igen'}, {'code': 0, 'value' :'Nem'}],

            // TODO valami központi helyre tenni... 
            sondaOptions: [{id:'1',name:'nasogastricus szonda'},{id:'2',name:'nasojejunalis szonda'},{id:'3',name:'endoscopos gastrostoma'},{id:'4',name:'sebészi gastrostoma'}
                          ,{id:'5',name:'sebészi jejunostoma'},{id:'6',name:'endoscopos jejunostoma'},{id:'E',name:'egyéb'}],
            sondatypeOptions: [{id:'1',name:'nasogastricus szonda'},{id:'2',name:'nasojejunalis szonda'},{id:'3',name:'PEG'},{id:'4',name:'PEJ'}
                          ,{id:'5',name:'gastrotubus'},{id:'6',name:'button'},{id:'7',name:'katéter'},{id:'8',name:'jejunokath'},{id:'E',name:'egyéb'}],
            feedtypeOptions: [{id:'B',name:'bólus'},{id:'G',name:'gravitációs szerelék'},{id:'P',name:'pumpa'},{id:'E',name:'szájon át étkezik'}],
            condOptions: [{id:'A',name:'teljes ápolásra szorul'},{id:'S',name:'segítségre szorul'},{id:'O',name:'önellátó'}],
            activityOptions: [{id:'J',name:'fennjáró beteg',energy:25},{id:'F',name:'fekvő beteg',energy:20}],

            visszavonas: {'patient_id': null, 'reason': null, 'note': ""},
            reasonOptions: [{id:'3',name:'Intézménybe került'},{id:'4',name:'Nem kéri az ápolást'},{id:'5',name:'Exitus'}],

            fileUploadKey: 0,
            uploadedFileId: null,
            uploadedFileName: null,
            isUploading: false, 
            isOpenFileViewer: false,
            viewFileData,
            viewFileName,
		}
	},
    validations: {
        patient: {
            title: {},
            name: { required },
            sex: { required },
            taj: { required },
            city: { required },
            postcode: { required },
            address1: { required },
            tel1: { required },
            birthdate: { required, validDate },
            firstvisitdate: { required, validDate },
            fmp_consent: { required: requiredIf(function(){
                    return this.$dayjs(this.patient.firstvisitdate).format('YYYYMMDD') > '20220830' 
                }) 
            },
        },
        sochome: {
            name: { required },
            city: { required },
            postcode: { required },
            address1: { required },
            tel1: { required }
        },
        uploadedFileName: { required: requiredIf(function(){
                return this.$dayjs(this.patient.firstvisitdate).format('YYYYMMDD') > '20220830' 
                    && !this.notEmpty(this.patient.patientfile_id)
            }) 
        },
        visszavonas: {
            reason: { required },
            note: {},
        }
    },
    patientService: null,
	hospitalService: null,
	created() {
		this.patientService = new PatientService()
        this.hospitalService = new HospitalService()

        if(this.isAdmin){
            this.extraColumns = [{field: 'homecarer', header: 'Ápoló'}]
        }

        this.lazyParams = {
            first: 0,
            page: 1,
            rows: 10,
            sortField: 'name',
            sortOrder: 1,
            filters: null,
            closed: (this.showClosed) ? 1: 0
        };

        this.showOnlySocHomes = localStorage.onlysochomes === "true" ? true : false
        this.showOnlyFelvettek = localStorage.onlyfelvettek=="true" ? true : false

        if(localStorage.patient_list_page === undefined) localStorage.patient_list_page = 1 

    },
    beforeMount() {
        //this.loadCodeStore('patientClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
        this.loadLazyData();
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
        loadLazyData() {
            this.loading = true;

            delete this.lazyParams.originalEvent
            delete this.lazyParams.pageCount
            delete this.lazyParams.filterMatchModes
            this.lazyParams.filters = this.searchGlobal
            this.lazyParams.closed = (this.showClosed) ? 1 : 0
            if(this.showOnlySocHomes === true)  this.lazyParams.onlysochomes = 1
            else                                delete this.lazyParams.onlysochomes
            if(this.showOnlyFelvettek === true)  this.lazyParams.onlyfelvettek = 1
            else                                delete this.lazyParams.onlyfelvettek

            this.patientService.getPatients(this.lazyParams)
            .then( result => {
                this.felvettekSzama = result.totalStatusRecorded
                this.totalRecords = result.totalRecords
                this.patients = result.data
                this.loading = false
                this.init = false

            })
        },
        async loadDoctors(patientid) {

            let doc = {}

            const doctors = await this.patientService.getPatientDoctors(patientid)

            let doctorE = ""
            if(this.notEmpty(doc = doctors.find(d => d.type == 'E'))){
                doctorE = doc.name
            }

            let doctorK = ""
            if(this.notEmpty(doc = doctors.find(d => d.type == 'K'))){
                doctorK = doc.name
            }

            let doctorT = ""
            if(this.notEmpty(doc = doctors.find(d => d.type == 'T'))){
                doctorT = doc.name
            }

            return { doctorE, doctorK, doctorT }

        },
        async loadStatus(patientid) {

            let status = await this.patientService.getPatientStatus(patientid)

            let option = this.sondaOptions.find(o => o.id == status.sonda)
            status.sondaName = option==undefined ? "" : option.name

            option = this.sondatypeOptions.find(o => o.id == status.sondatype)
            status.sondatypeName = option==undefined ? "" : option.name

            option = this.feedtypeOptions.find(o => o.id == status.feedtype)
            status.feedtypeName = option==undefined ? "" : option.name

            option = this.condOptions.find(o => o.id == status.cond)
            status.allapotName = option==undefined ? "" : option.name

            option = this.activityOptions.find(o => o.id == status.activity)
            status.activityName = option==undefined ? "" : option.name

            return status

        },
        onPage(event) {
            this.lazyParams = event
            this.lazyParams.page++
            //localStorage.patient_list_page = this.lazyParams.page
            this.loadLazyData()
        },
        onSort(event) {
            this.lazyParams = event
            this.loadLazyData()
        },
        onFilter() {
            this.loading = true
            this.lazyParams.page = 1
            this.loadLazyData()
            this.loadingSearch = false
        },
        onStateRestore(event) {
            //console.log('state-restore')
            //console.log(event)
            this.showClosed = localStorage.patients_closed=="true" ? true : false
            this.showOnlyFelvettek = localStorage.onlyfelvettek=="true" ? true : false

            this.lazyParams = event
            this.lazyParams.page = Math.floor(this.lazyParams.first / this.lazyParams.rows) + 1
            if(localStorage.patients_globalfilter !== undefined) {
                this.searchGlobal = localStorage.patients_globalfilter 
            }
            this.expandedRows = []
        },
        async expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.patients.filter(p => p.id == e.data.id);
                if(this.expandedRows[0].status == 2){
                    await this.loadExpandStatusDetails()
                }
            }else{
                this.expandedRows = []
            }
        },
        async loadExpandStatusDetails() {
            this.expandedDetailsLoading = true

            if(this.notEmpty(this.expandedRows[0].address2)){
                const hospitalName = await this.hospitalService.getHospitalName(this.expandedRows[0].address2)
                this.expandedRows[0].address2_name = hospitalName ?? ""
            }
            if(this.notEmpty(this.expandedRows[0].rephospital)){
                const hospitalName = await this.hospitalService.getHospitalName(this.expandedRows[0].rephospital)
                this.expandedRows[0].rephospital_name = hospitalName ?? ""
            }
            this.expandedRows[0].statusDetails = await this.loadStatus(this.expandedRows[0].id)
            this.expandedRows[0].doctorDetails = await this.loadDoctors(this.expandedRows[0].id)

            this.expandedDetailsLoading = false
        },
        fullAddress(data){
            return data.postcode + '. ' + data.city + ', ' + data.address1
        },
        openDialog(){
            this.patientDialog = true
            this.submitted = false
            this.validIRSZ = true
            this.validTAJ = true
            this.uploadedFileId = null
            this.uploadedFileName = null
            this.$nextTick(() => { this.v$.$reset() })
        },
        openSocHomeDialog(){
            this.socHomeDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
        openRecordDialog(){
            this.isOpenPatientRecordDialog = true
        },
        dialogShow() {
            this.$refs.firstEditField.$el.focus()
        },
        dialogShow2() {
            this.$refs.firstEditField2.$el.focus()
        },
		openNew() {
            this.patient = {in_social_home: false, new: true}
            this.openDialog()
		},
		openNewSocHome() {
            this.sochome = {}
            this.openSocHomeDialog()
		},
		openNewRecord() {
            this.dialogData = {in_social_home: false, new: true}
            this.openRecordDialog()
		},
		hideDialog() {
			this.patientDialog = false
			this.socHomeDialog = false
            this.submitted = false
            this.validIRSZ = true
            this.validTAJ = true
		},
        searchSocHome(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.patientService.getSocHomesForSelect({filters: event.query })
                .then( result => {
                    this.filteredSocHomes = result
                })
            }, 600)
        },
        async savePatient() {
            this.v$.patient.$touch()
            this.v$.uploadedFileName.$touch()
            if (!this.v$.patient.$invalid && !this.v$.uploadedFileName.$invalid) {
                let oSex = this.optionsSex.find(o => o.code == this.patient.sex)
                this.patient.sex_val = oSex.value
                this.patient.social_home =  (this.patient.social_home_selected== undefined) ? '' : this.patient.social_home_selected.id
                this.patient.type = 'P'
                return await this.save()
            }
        },
        saveSocHome() {
            this.v$.sochome.$touch()
            if (!this.v$.sochome.$invalid) {
                this.patient = this.sochome
                this.patient.type = 'S'
                this.save()
            }
        },
        async savePatientAndForward() {
            let id = await this.savePatient()
            if(id)
                this.$router.push({ name: 'patient', params: { patientId: id }, query: { new: 1 } })
        },
		async save() {

            this.submitted = true

            try{
                let title = (this.patient.title == undefined) ? '' : this.patient.title+' '
                this.patient.title_name = title + this.patient.name

                if(this.notEmpty(this.uploadedFileId)){
                    this.patient.patientfile_id = this.uploadedFileId
                    this.patient.patientfile_name = this.uploadedFileName
                }

                if (this.patient.id && this.patient.id < 200000000) {
                    this.patient.id = await this.patientService.updatePatient(this.patient)
                    this.patients[this.findIndexById(this.patient.id)] = this.patient
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                }
                else {
                    const newPatient = await this.patientService.newPatient(this.patient)
                    this.patient.id = newPatient.id
                    this.patient.homecarer = newPatient.homecarer
                    this.patient.status = '0'
                    if(this.patients.length == this.$refs.dt.rows){
                        this.patients.pop()
                    }
                    this.patients.unshift(this.patient)
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                }
                this.hideDialog()
            }catch(e){
                if(e.response.status === 422){
                    let keys = Object.keys(e.response.data)
                    let values = Object.values(e.response.data)

                    this.errors = new Map();
                    for (let i = 0; i < keys.length; i++){
                        this.errors.set(keys[i], values[i]);
                    }
                    
                    if(this.errors.has("IRSZ")){
                        this.validIRSZ = false;
                    }
                    if(this.errors.has("TAJ")){
                        this.validTAJ = false;
                    }
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: this.errors.entries().next().value[1], life: 3000})                             
                }
                else{
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Valami hiba történt!", life: 3000})
                }                
            }

            this.submitted = false

            return this.patient.id

		},
		detailsPatient(id) {
            this.$router.push({ name: 'patient', params: { patientId: id }})
		},
        edit(data){
            if(data.type=='S'){
                this.editSocHome(data.id)
            }else{
                this.editPatient(data.id)
            }
        },
		editPatient(id) {
            this.patient = JSON.parse(JSON.stringify(this.patients.find( d => d.id === id)))
            if(this.patient.social_home !== "" && this.patient.social_home !== null){
                this.patient.in_social_home = true
                if(this.isAdmin){
                    this.patientService.getSocHomesForSelect({filters: this.patient.social_home })
                        .then( result => {
                            this.filteredSocHomes = result
                            //const val = (this.patient.social_home) ? {id: this.patient.social_home, name: this.patient.social_home} : ""
                            //Vue.set(this.patient, 'social_home_selected', result[0])
                            this.patient['social_home_selected'] = result[0]
                        })
                }
            }
            
            //this.patient.postcode = parseInt(this.patient.postcode)
            if((this.isAdmin || this.isManager) && this.patient.status == "2"){
                this.dialogData = this.patient
                this.openRecordDialog()
            }else{
                this.openDialog()
            }
		},
		editSocHome(id) {
            this.sochome = JSON.parse(JSON.stringify(this.patients.find( d => d.id === id)))
            //this.patient.postcode = parseInt(this.patient.postcode)
            this.openSocHomeDialog()
		},
		confirmDeletePatient(patient) {
			this.patient = patient
			this.deletePatientDialog = true
		},
		async deletePatient() {
            this.submitted = true
            try{
                await this.patientService.deletePatient(this.patient.id)
                this.patients = this.patients.filter(val => val.id !== this.patient.id)
                this.deletePatientDialog = false
                this.patient = {}
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }
            this.submitted = false
		},
		unRecord(patient) {
			this.patient = patient
            this.visszavonas.patient_id = patient.id
            //this.visszavonas.patient_id = patient.id homecarer kéne
			this.unRecordDialog = true
		},
		async unRecordPatient() {
            this.submitted = true
            this.v$.visszavonas.$touch()
            if (!this.v$.visszavonas.$invalid) {
                try{
                    await this.patientService.unRecordPatient(this.visszavonas) 
                    this.patients = this.patients.filter(val => val.id !== this.patient.id)
                    this.unRecordDialog = false
                    this.patient = {}
                    this.visszavonas = {'patient_id': null, 'reason': null, 'note': ""}
                    this.totalRecords--
                    this.felvettekSzama--
                    //this.$v.visszavonas.$reset();
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Visszavonva!', life: 3000})
                }catch{
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                }
            }
            this.submitted = false
		},
		findIndexById(id) {
			let index = -1
			for (let i = 0; i < this.patients.length; i++) {
				if (this.patients[i].id === id) {
					index = i
					break
				}
			}
			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV()
		},
        clickInputMask(elid) {
            if(this.$refs[elid].modelValue == ""){
                document.getElementById(elid).setSelectionRange(0,0)
            }
        },
        checkErrors: function(){
            if(this.v$.patient.postcode.$error){ 
                this.validIRSZ = true
            }
            if(this.v$.patient.taj.$error){ 
                this.validTAJ = true
            }
        },
        setFiltersDefault(){
            this.showClosed = false
            this.showOnlySocHomes = false
            this.searchGlobal = "" // this.init nem működött... 
        },
        setDefaultAndLoad() {
            this.init = true
            this.setFiltersDefault()
            this.loadLazyData()
        },
        notEmpty(val) {
            // TODO ez is lehetne valami mixin, sok helyen kéne használni
            return val!==null && val!=='' && val!==undefined
        },
        async myFileUploader(event) {

            this.isUploading = true

            if(!this.notEmpty(this.patient.id)) // || this.patient.id > 200000000 ) // miért kellene újat adni, ha már van temporary? így nemtudjuk törölni a temporary-kat
                this.patient.id = '2000' + Math.floor(Math.random() * (90000) + 10000) // Lehet, hogy ez mindegy... 

            try{
                const uploadedFile = await this.patientService.uploadPatientFile(this.patient.id, event.files[0])
                //this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Feltöltve!', life: 3000})
                this.uploadedFileId = uploadedFile.id
                this.uploadedFileName = uploadedFile.filename

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Valami hiba történt!", life: 3000})
                console.log(e)
                this.uploadedFileId = null
                this.uploadedFileName = null
            }

            this.isUploading = false
            this.fileUploadKey++

        },
        async viewPatientFile(fileId) {

            try{
                const downloadedFile = await this.patientService.downloadPatientFile(fileId)

                this.viewFileName = Buffer.from(downloadedFile.headers['x-filename'], 'base64')

                const blo = new Blob([downloadedFile.data])
                this.viewFileData = window.URL.createObjectURL(blo)

                this.isOpenFileViewer = true;

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Nincs ilyen fájl!", life: 3000})
                console.log(e)
            }
        },
        async downloadPatientFile(fileId) {

            try{
                const downloadedFile = await this.patientService.downloadPatientFile(fileId)

                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Letöltve!', life: 3000})

                const fileName = Buffer.from(downloadedFile.headers['x-filename'], 'base64')

                const blo = new Blob([downloadedFile.data])
                const url = window.URL.createObjectURL(blo)
                const link = document.createElement('a')

                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Nincs ilyen fájl!", life: 3000})
                console.log(e)
            }
        },
        fileSecondaryButton(param) {
            return [{
					label: 'Letölt',
					icon: 'pi pi-download',
					command: () => {
						this.downloadPatientFile(param)
					}
            }]

        },
        isHianyos: function(data) {
            return (data.type!=='S' && !data.birthdate && !data.fmp_consent)
        },
        setFirstVisitToday() {
            this.patient.firstvisitdate = this.$dayjs().format('YYYY.MM.DD')
        }
    }, 
    watch: {
        searchGlobal(val) {
            if(this.init){
                this.filters.global = val
                localStorage.patients_globalfilter = val
            }else{
                clearTimeout(this.searchInterval)
                this.searchInterval = setTimeout(() => {
                    this.loadingSearch = true
                    setTimeout(() => { 
                        this.filters.global = val
                        localStorage.patients_globalfilter = val
                        this.onFilter();
                    }, 20)
                }, 600)
            }
        },
        showOnlyFelvettek(v) {
            localStorage.onlyfelvettek = v
        },
        showOnlySocHomes(v) {
            localStorage.onlysochomes = v
        },
        showClosed(v) {
            localStorage.patients_closed = v
        },
        isOpenPatientRecordDialog: function(val) {
            if(!val && this.dialogData.saved){
                this.patient = this.dialogData 

                if(this.patient.new){
                    this.patient.new = false

                    if(this.patients.length == this.$refs.dt.rows){
                            this.patients.pop()
                        }
                    this.patients.unshift(this.patient)
                }
            }
        },
    },
    computed: {
/*        ...mapGetters({
            codesPatientClass: 'codeStore/patientClass'
        })  
        ...mapGetters({
            user: 'user/User'
        }),*/
        isAdmin: function(){
            return this.User.role == "ADMIN"
        },
        isManager: function(){
            return this.User.role == "MANAGER"
        },
        isIRSZValid: function(){
            this.checkErrors()
            return this.validIRSZ
        },
        isTAJValid: function(){
            this.checkErrors()
            return this.validTAJ
        }
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
    flex-flow: row;
	align-items: center;
	justify-content: space-between;
}

.patient-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .patient-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.patient-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;
}

.p-badge{border-radius:2px;padding:.25em .5rem;font-weight:700;font-size:11px;letter-spacing:0px;line-height: unset;height: unset;min-width: 1.5rem !important;}
.p-badge.visit-status-K{background-color:#b3e5fc;color:#23547b}
.p-badge.visit-status-J{background-color:#feedaf;color:#8a5340}
.p-badge.visit-status-T{background-color:#f3fff3;color:#47944c}
.p-badge.visit-status-A{background-color:#a5a2a2;color:#eeeeee}
.p-badge.visit-status-X{background-color:#ffcdd2;color:#c63737}
.p-badge.fmp{background-color:#c8e6c9;color:#256029;margin-top:.2rem;}


#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}

.l-fileupload-label {
    margin-bottom: 0.6rem;
    display: block;    
    margin-left: 1rem;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

.l-nonmobile {
    display: initial;
}

.l-mobile {
    display: none;
}

.l-break {
  height: 0;
}

@media screen and (max-width: 1024px) {
    .l-nonmobile {
    display: none !important;
    }

    .l-mobile {
        display: initial !important;
    }

    .l-break {
        flex-basis: 100%;
        height: 0;
    }

    .l-filter {
        margin-top: 12px !important;
    }

    .table-header {
        flex-flow: wrap;
    }

    h5 {
        margin-top: 12px !important;
    }
    .p-col {
        flex-basis: content !important;
    }
    .p-col-2 {
        width: 33%;
    }
    .p-col-10 {
        width: 66%;
    }

}

</style>

<style scoped lang="postcss">

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
